<template>
  <div id="new-contact">
    <div class="close" @click="$emit('close')">
      <img src="@/assets/delete.png" alt="close" />
    </div>

    <div class="loading" v-if="isLoading">
      <span>Loading</span>
      <img src="@/assets/loading.svg" alt="loading" />
    </div>

    <div class="success" v-if="isSuccess">
      Your message was successfully sent.
    </div>

    <div class="title">Contact</div>

    <form
      @submit.prevent="submit"
      id="new-contact-form"
      class="form"
      novalidate
    >
      <div class="basic-data step" :class="{ active: step == 1 }">
        <InputField
          name="firstName"
          :value="user.firstName"
          @update:value="user.firstName = $event"
          label="First Name"
          :required="true"
          :errors="errors"
        />
        <InputField
          name="lastName"
          :value="user.lastName"
          @update:value="user.lastName = $event"
          label="Last Name"
          :required="true"
          :errors="errors"
        />
        <InputField
          name="email"
          :value="user.email"
          @update:value="user.email = $event"
          label="Email"
          :required="true"
          :errors="errors"
        />

        <div class="buttons">
          <a
            @click="nextStep"
            class="button"
            :class="{ disabled: !isStepValid(1), primary: isStepValid(1) }"
          >
            Next
          </a>
        </div>

        <!--
        <div class="horizontal-line"></div>

        <div class="accounts">
          <strong>Already have an account?</strong>

          <a href="https://portal.harryfox.com" class="button primary">Login to my publisher account</a>
          <a href="https://secure.harryfox.com/common/eMechanical.jsp" class="button primary">Login to my licensing account</a>
          <a href="https://secure.harryfox.com/public/songfile.jsp" class="button primary">Login to my Songfile account</a>
        </div>
        --->
      </div>

      <transition name="slide">
        <div class="faq step" v-show="step >= 2" :class="{ active: step == 2 }">
          <strong>Which best describes your question?</strong>

          <select v-model="selectedQuestion">
            <option value="">Select a question:</option>
            <option
              v-for="(item, index) in questions"
              :key="index"
              :value="item.question"
            >
              {{ item.question }}
            </option>
          </select>

          <transition name="slide">
            <div class="answers" v-show="selectedQuestion != ''">
              <div
                v-for="(item, index) in questions"
                :key="index"
                v-show="item.question == selectedQuestion"
                v-html="item.answer"
              ></div>
            </div>
          </transition>

          <div class="buttons">
            <a @click="prevStep" class="button default">Back</a>

            <a
              @click="nextStep"
              class="button"
              :class="{ disabled: !isStepValid(2), primary: isStepValid(2) }"
            >
              Next
            </a>
          </div>
        </div>
      </transition>

      <transition name="slide">
        <div
          class="helped step"
          v-show="step == 3"
          :class="{ active: step == 3 }"
        >
          <strong>Did that answer your question?</strong>

          <div class="buttons yes-no">
            <a
              @click="didHelp(true)"
              class="button default"
              :class="{ primary: helped == true }"
            >
              YES
            </a>
            <a
              @click="didHelp(false)"
              class="button default"
              :class="{ primary: helped == false }"
            >
              NO
            </a>
          </div>

          <div
            class="input-field"
            :class="{ 'has-error': hasError('service') }"
            v-show="helped == false"
          >
            <select name="service" id="service-select" v-model="user.service">
              <option value="">What service is your question about?*</option>
              <option v-for="item in services" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
            <div class="errors">
              <ul>
                <li
                  v-for="error in errors.filter(
                    error => error.field == 'service'
                  )"
                  :key="error.field"
                >
                  * {{ error.error }}
                </li>
              </ul>
            </div>
          </div>

          <div class="additional-info">
            <p v-show="helped == true">
              Great!<br /><br />
              If you still have a question please enter it below:<br /><br />
              Thank you!
            </p>

            <p v-show="helped == false || helped == null">
              <span v-show="helped == false">
                Sorry about that.
                <br /><br />
              </span>
              Please enter your question or additional details here.
              <br /><br />
              Thank you
            </p>

            <textarea v-model="user.message" :disabled="helped == null">
            </textarea>
          </div>

          <vue-recaptcha
            sitekey="6LfSeqQUAAAAABK7gRyGNeumDoEda8ga3tmJ1vw0"
            @verify="onVerify"
          >
          </vue-recaptcha>

          <div class="buttons">
            <a @click="prevStep" class="button default">Back</a>

            <button
              type="submit"
              class="button primary"
              :class="{ disabled: disableSubmit }"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </transition>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import InputField from "@/components/InputField.vue";
import UsefulLib from "@/services/useful-lib.js";
import ContactQuestions from "@/services/contact-questions.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "NewContact",
  components: {
    InputField,
    VueRecaptcha
  },
  data: function() {
    return {
      isLoading: false,
      isSuccess: false,
      isMobile: false,
      step: 1,
      helped: null,
      verified: false,
      errors: [],
      user: {
        firstName: "",
        lastName: "",
        email: "",
        message: "",
        service: "",
        isClient: "n/a"
      },
      services: ["eMech", "Songfile", "HFA General", "Rumblefish General"],
      questions: ContactQuestions.questions,
      selectedQuestion: ""
    };
  },
  computed: {
    disableSubmit: function() {
      if (this.helped == null) {
        return true;
      } else if (
        this.helped == false &&
        (this.user.service == "" || this.user.message == "")
      ) {
        return true;
      } else {
        return !this.verified;
      }
    }
  },
  methods: {
    onVerify: function() {
      console.log("VERIFIED!");
      this.verified = true;
    },
    didHelp: function(answer) {
      this.helped = answer;
    },
    isStepValid: function(step) {
      if (step == 1) {
        return (
          this.user.firstName != "" &&
          this.user.lastName != "" &&
          this.user.email != "" &&
          UsefulLib.validateEmail(this.user.email)
        );
      } else if (step == 2) {
        return this.selectedQuestion != "";
      } else return false;
    },
    prevStep: function() {
      if (this.step == 1) {
        return false;
      }

      this.step--;
    },
    nextStep: function() {
      console.log("NEXT STEP");
      if (!this.isStepValid(this.step)) {
        return false;
      }

      this.step++;
    },
    deviceType: () => UsefulLib.detectDeviceType(),
    hasError: function(name) {
      return this.errors.filter(error => error.field == name).length;
    },
    validateForm: function() {
      this.errors = [];

      for (let key in this.user) {
        let value = this.user[key];

        if (key == "service" && this.helped == true) {
          this.user.service = "HFA General";
          continue;
        }

        if (!value) {
          this.errors.push({ field: key, error: "Field Required" });
        }
        if (
          key == "email" &&
          !this.errors.find(error => error.field == "email")
        ) {
          if (!UsefulLib.validateEmail(value)) {
            this.errors.push({ field: key, error: "Email is invalid" });
          }
        }
      }
    },
    submit: function(e) {
      this.validateForm();

      if (!this.errors.length) {
        this.isLoading = true;
        let $this = this;
        let user = { ...this.user };
        let url = process.env.VUE_APP_API_URL.replace("users", "contact");

        axios
          .post(url, user)
          .then(function() {
            $this.isLoading = false;
            $this.isSuccess = true;
          })
          .catch(function(error) {
            console.log(error);
            $this.isLoading = false;
            alert(
              "Sorry, there was a problem sending your message. Please try again later."
            );
          });
      }

      e.preventDefault();
    }
  }
};
</script>

<style lang="scss">
.v--modal-overlay .v--modal-background-click {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-contact-form-modal {
  overflow: visible !important;
  width: auto !important;
  height: auto !important;
  top: initial !important;
  left: initial !important;
  transition: all 200ms ease-in-out;
}
#new-contact {
  position: relative;
  width: calc(100vw - 16px);
  height: calc(100vh - 16px);
  padding: 8px;

  @media (min-width: $desktop) {
    padding: 20px;
    width: auto;
    height: auto;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 4;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .loading,
  .success {
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
    font-size: 1.5em;
    text-align: center;

    &.loading img {
      width: 100px;
    }
  }

  .title {
    font-family: "Fjalla One", sans-serif;
    font-weight: 400;
    font-size: 32px;
    margin: 0 0 20px;
    border-bottom: 2px solid #eee;
  }

  .form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $desktop) {
      flex-direction: row;
    }

    .horizontal-line {
      height: 3px;
      background-color: #eee;
      width: 100%;
      margin: 30px 0;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;

      &.yes-no {
        justify-content: flex-start;
        margin-bottom: 20px;

        .button {
          margin-right: 10px;
        }
      }
    }

    .accounts {
      strong {
        display: block;
      }

      a {
        display: block;
        margin-top: 10px;
      }
    }

    .input-field {
      select {
        width: 100%;
      }

      &.has-error {
        select {
          border-color: red !important;
        }
      }
    }

    .step {
      position: absolute;
      background-color: #fff;
      width: 100%;
      height: 90vh;
      z-index: 1;
      overflow: auto;

      select {
        border: 1px solid #999;
        width: 100%;
        display: block;
        background-color: #fff;
        margin-top: 10px;
        font-size: 0.8em;
        padding: 0.6em;
        border-radius: 3px;
        z-index: 2;
        position: relative;
        box-shadow: 0px 5px 7px -5px rgba(0, 0, 0, 0.5);
      }

      &.active {
        z-index: 2;
      }

      &.slide-enter-active,
      &.slide-leave-active {
        transition: opacity 0.5s ease-in-out;
      }
      &.slide-enter,
      &.slide-leave,
      &.slide-leave-to {
        opacity: 0;
      }

      &.faq {
        .answers {
          height: 60vh;
          overflow: auto;
          position: relative;
          border: 1px solid #999;
          border-top: 0;
          border-radius: 0 0 3px 3px;
          margin-top: -4px;
          padding: 15px 5px 0;
          z-index: 1;

          &.slide-enter-active,
          &.slide-leave-active {
            transition: height 0.3s ease-out;

            * {
              opacity: 0;
            }
          }
          &.slide-enter,
          &.slide-leave,
          &.slide-leave-to {
            height: 0px;
          }
        }
      }

      &.helped {
        textarea {
          display: block;
          width: calc(100% - 1.2em - 2px);
          height: 100px;
          padding: 0.6em;
          margin: 10px 0;
          border-radius: 3px;
        }
        .additional-info {
          margin: 20px 0 0;
        }
      }

      @media (min-width: $desktop) {
        width: 310px;
        position: relative;
        height: auto;

        * {
          opacity: 1;
          transition: opacity 0.2s ease-in-out;
        }

        .buttons:not(.yes-no) {
          justify-content: flex-end;

          a.button.default {
            display: none;
          }
        }

        &.faq {
          .answers {
            height: 300px;
          }

          a,
          router-link {
            text-decoration: underline;
          }
        }

        &.faq,
        &.helped {
          padding: 0 0 0 20px;
          margin: 0 0 0 20px;
          border-left: 1px solid #ccc;
        }

        &.slide-enter-active,
        &.slide-leave-active {
          transition: width 0.5s ease-in-out;
          * {
            display: none;
          }
        }
        &.slide-enter,
        &.slide-leave,
        &.slide-leave-to {
          width: 0px;
        }
      }
    }
  }
}
</style>
