import blog from "../../api/blog-posts";

const state = {
  posts: blog.posts
};

const getters = {
  postById: state => id => {
    return state.posts.find(item => item.id == id);
  }
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
