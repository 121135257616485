<template>
  <div class="input-field" :class="{ 'has-error': hasError(name) }">
    <input
      :disabled="disabled"
      type="text"
      @focus="onFocus"
      class="field"
      :id="name"
      :name="name"
      v-model="model"
      :placeholder="placeholder"
      v-if="!mask"
      @keyup="preventNonASCII"
    />
    <input
      :disabled="disabled"
      type="text"
      @focus="onFocus"
      v-mask="mask"
      class="field"
      :id="name"
      :name="name"
      v-model="model"
      :placeholder="placeholder"
      v-if="mask"
    />
    <div class="errors">
      <ul>
        <li
          v-for="error in errors.filter(error => error.field == name)"
          :key="error.field"
        >
          * {{ error.error }}
        </li>
      </ul>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  name: "InputField",
  directives: {
    mask
  },
  props: ["name", "value", "label", "required", "errors", "mask", "disabled"],
  data: function() {
    return {
      model: this.value
    };
  },
  watch: {
    model: function(current) {
      this.$emit("update:value", current);
    }
  },
  computed: {
    placeholder: function() {
      let str = this.label;

      if (this.required) {
        str += "*";
      }

      return str;
    }
  },
  methods: {
    onFocus: function(event) {
      this.$emit("focus", event);
    },
    hasError: function(name) {
      return this.errors.filter(error => error.field == name).length;
    },
    preventNonASCII: function() {
      let reg;

      if (/email/g.test(this.name)) {
        reg = /[!#$%^&*?]/g;
      } else if (/address/g.test(this.name)) {
        reg = /[!@$%^&*?]/g;
      } else {
        reg = /[!@#$%^&*?]/g;
      }

      if (this.model !== undefined && this.model !== null) {
        this.model = this.model.replace(/[^\x20-\x7E]/g, "").replace(reg, "");
      }
    }
  }
};
</script>

<style lang="scss">
.input-field {
  width: 100%;
  margin-bottom: 20px;
  position: relative;

  &.has-error {
    input {
      border-color: red;
    }
  }

  .field {
    width: calc(100% - 0.6em);
    border: 0;
    outline: none;
    padding: 0.3em;
    border-bottom: 1px solid #999;
    font-family: "Abel", sans-serif;
    font-size: 1em;
  }
  .tooltip {
    display: none;
    color: #fff;
    background-color: $blue;
    padding: 10px;
    position: absolute;
    z-index: 1;
    border-radius: 3px;
  }
  .field:focus ~ .tooltip {
    display: block;
  }

  .errors {
    position: absolute;
    bottom: 0;
    left: 0;
    color: red;
    transform: translateY(100%);
    font-size: 0.8em;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: block;
      }
    }
  }
}
</style>
