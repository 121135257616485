const questions = [
  {
    question:
      "How do I create an account with HFA to collect mechanical royalties?",
    answer:
      "<p>If you are interested in HFA representing your publishing entity for U.S mechanical royalties, please see the below information regarding the two publishing accounts we offer.</p><p>We have two kinds of accounts: affiliated and non-affiliated.</p><p>By affiliating with HFA, publishers have access to a range of licensing, collection, distribution, royalty compliance and legal services to assist them in administrating their catalog. In addition to having HFA handle your licensing and collections needs, becoming an affiliate of HFA provides full access to:</p><ul><li>Royalty compliance services</li><li>Income tracking services</li><li>A dedicated agent for inquiry handling</li><li>New media licensing opportunities</li><li>Online song registration and updating tools</li><li>A variety of online reports and analytical tools</li></ul><p>Please note that you must administer your own publishing in order to create an account with HFA. If your compositions are already administered by a publisher, you will need to contact your publisher directly with any questions regarding mechanical royalties.</p><p>To apply for affiliation, please complete the online general information form at <a href='https://www.harryfox.com/#/hfa-affiliate'>www.harryfox.com/#/hfa-affiliate</a>. Once you have completed the form, you will be instructed to select the administration services that you would like HFA to provide on your behalf. You can then view our Publisher Affiliation Agreement and submit your application fee through PayPal. Please allow up to four weeks for processing.</p><p>If you do not want to affiliate with HFA, but still want to register your catalog in our database, you can create an HFA non-affiliated account. With this account, you can register your titles, access royalty reports, and see current licenses. You can create this account for free here: <a href='https://www.harryfox.com/#/register-songs'>www.harryfox.com/#/register-songs</a></p>"
  },
  {
    question:
      "I already have a publisher account. How do I update my account information?",
    answer:
      "<p>To update your address or tax information, please complete and return an updated W9 form per our contact information below. You can download a W9 form <a href='https://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>here</a>.</p><p>To enroll in direct deposit or update your direct deposit information, please complete and return our Direct Deposit Form along with a copy of a voided check to confirm your account and routing numbers. If you do not have a voided check, please include an official bank document that clearly displays the name on the account, as well as the account and routing numbers. You can download our Direct Deposit Form <a href='https://d3men7790u44o8.cloudfront.net/content/direct_deposit_form.pdf' target='_blank'>here</a>.</p><p>Documents can be sent via email, fax, or post per the information below:</p><p><b>E-Mail</b><br><a href='mailto:clientservices@harryfox.com'>clientservices@harryfox.com</a></p><p><b>Fax</b><br>(646) 487-6779</p><p><b>Mail</b><br>HFA<br>Attn: Client Services<br>40 Wall Street, 6th Floor<br>New York, NY 10005</p>"
  },
  {
    question: "I already have a publisher account. How do I register my songs?",
    answer:
      "<p>You can follow the below instructions to register your songs using eSong: </p><p>1. Log into your HFA Account at <a href='https://portal.harryfox.com' target='_blank'>https://portal.harryfox.com</a></p><p>2. Under Navigation, click on &quot;Asset Management&quot;. In the Compositions section of the page, click &quot;eSong&quot;. </p><p>3. Enter your song information. If you are registering new works, you only need to enter the following information for each registration: </p><p>Song title <br />Writer's first and last name</p><p>4. Click &quot;Search&quot; </p><p>5. Next, you will be prompted to add your HFA publisher name and/or HFA P#, and Ownership share. There are also fields for ISRC and general song information. These are optional but highly recommended! </p><p>*Please note, you should not include the &quot;%&quot; symbol when submitting your Ownership Share. Simply enter your claim without the % sign. For example, a 100% claim would be entered as '100'. </p><p>Once submitted, you will be returned to the 'Song Submission History' tab, which displays a list of the request you have submitted through eSong, along with the status of each request. You can see the details of each request by clicking on the request number.</p><p>If you wish to register more than 50 songs, you can use our eSong Bulk application. To download our eSong Bulk template, click on the &ldquo;Submit Bulk File&rdquo; tab in eSong followed by &ldquo;New eSong Template.&rdquo;</p>"
  },
  {
    question:
      "I already have a publisher account. How do I view my statements?",
    answer:
      '<p>Statements can be downloaded in an Excel format via the HFA Portal.</p><p>To access your statements, go to <a href=\'https://portal.harryfox.com\'>https://portal.harryfox.com</a> (please use Mozilla Firefox or Google Chrome) and log in with your email and password. If you have never visited this page before, please click "first time here?".</p><p>Once you are logged in:</p><p>1. On the home page click the link for “STATEMENTS”.</p><p>2. Click on the "STATEMENT" button for the Check # you wish to view.</p><p>3. A menu window will appear and you can select the type and format for the statement you\'d like to download.</p>'
  },
  {
    question:
      "How do I obtain a license for CDs, LPs, cassettes, permanent digital downloads, ringtones, or streams?",
    answer:
      "<p>If you are distributing less than 2,500 copies of the recording (CD, cassette, LP, permanent digital download, or ringtone) or less than 10,000 streams on a personal website, you can obtain limited quantity mechanical licenses using HFA’s Songfile®.</p><p>Please note that Songfile streaming licenses are valid only for streaming on personal websites. If you are looking to release your music on a third party streaming platform (such as Spotify, Apple Music, etc) you will need to inquire with the service about how they need the music licensed for their service, or inquire with your distributor.</p><p>To register an account with Songfile, go to <a href='www.songfile.com'>www.songfile.com</a> and select “REGISTER”.</p><p>After entering the necessary contact information fields, log in to your Songfile account by selecting, “Go to My Songfile.” Select “Get Started Now!” to enter your Songfile account, then “Get a License.”</p><p>Choose the type of license you need, and fill out the subsequent information regarding the license, search your song in the Songfile database, and select “Add to Cart.”</p><p>After providing your Record Information, you will be able to checkout by paying with a Credit Card or eCheck.</p><p>Songfile licenses are available within 24 hours. An email receipt of your purchase will be sent to you shortly after your purchases, as well as a notification email letting you know your licenses are available for downloading.</p><p>If you plan to make more than 2,500, please use the forms available at <a href='https://www.harryfox.com/#/setup-hfa-portal'>https://www.harryfox.com/#/setup-hfa-portal</a> to set up an HFA licensing account.</p>"
  },
  {
    question: "How do I view my licenses purchased through Songfile?",
    answer:
      '<p>To view your purchased licenses, first log in to your Songfile account at <a href=\'www.songfile.com\'>www.songfile.com</a>. Select "Get started now!" in the Songfile section of the homepage.</p><p>Select "View My Licenses”, and then the Order which you obtained this license for.</p>'
  },
  {
    question:
      "How do I correct or change the information on my Songfile license?",
    answer:
      "<p>After a Songfile license has been issued, we cannot update or change any information on that license.</p><p>Additionally, HFA cannot advise on whether your Songfile license needs to be updated or changed. You may wish to contact your duplicator or distributor to verify if your license needs to be updated or changed.</p><p>You must repurchase the license in order to correct any information currently on your license. If you purchase a new license, please send an email to <a href='mailto:clientservices@harryfox.com'>clientservices@harryfox.com</a> and indicate the old and new license and order numbers. We will be able to void the original licenses and process a refund on the original order, minus processing fees. Please note if a license is over 60 days old we cannot issue a refund for the license.</p>"
  },
  {
    question:
      "How do I obtain a license for my commercial, film, video, or YouTube channel?",
    answer:
      "<p><u>Synchronization Licenses</u></p><p>A synchronization license is an authorization to use recorded music in combination with visual images such as film, television, video, websites, video games, etc.</p><p>You may secure synchronization rights by contacting the publisher directly. You can use the following databases to locate publisher contact information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a></p><p>Some non-commercial limited quantity synchronization uses can also be licensed via <a href='https://search.rumblefish.com/'>search.rumblefish.com</a>. Please note, materials you create using <a href='https://search.rumblefish.com/'>search.rumblefish.com</a> licenses may not be sold.</p><p><u>YouTube</u></p><p>HFA does not issue licenses for users on YouTube, or any other online video service. However, YouTube has entered into a license agreement with many music publishers for when YouTube users upload videos that contain those music publisher’s songs.</p><p>This license enables YouTube users to legally include musical compositions in their user-generated content, as long as the user complies with the monetization process.</p><p>For more information on YouTube's content ID system, please click here.</p><p>For information on whether a particular publisher has a license agreement with YouTube, please contact the publisher directly.</p>"
  },
  {
    question:
      "How do I obtain a license for radio, TV broadcast, concerts, or other performances?",
    answer:
      "<p>HFA is often confused with the performing rights societies ASCAP, BMI, and SESAC. We suggest that you contact these companies if you are interested in obtaining performance rights for the use of musical compositions on the radio, in concert or for other performances. HFA does not provide any of these services.</p><p><a href='https://www.ascap.com/'>www.ascap.com</a><br><a href='https://www.bmi.com/'>www.bmi.com</a><br><a href='https://www.sesac.com/#/'>www.sesac.com</a></p>"
  },
  {
    question: "How do I license a sample?",
    answer:
      "<p>Sampling is the use of portions of an existing song or master recording in a new song or master recording. Sampling requires permission directly from the publisher of the sampled composition, as well as permission from the owner of the sampled master recording. This is usually a record company if the actual master is used.</p><p>HFA does not currently license samples on behalf of its publishers. You can use the following databases to locate publisher contact information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a></p>"
  },
  {
    question: "How do I license music for a podcast?",
    answer:
      "<p>HFA does not issue licenses for podcasts, as using music in conjunction with other forms of media falls outside the realm of a mechanical license. For more information regarding your project, you should contact the publisher of the songs you plan on including in your program. Please refer to the following websites for publisher contact information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a></p>"
  },
  {
    question:
      "I made an arrangement that contains significant changes to the original composition. What kind of license do I need?",
    answer:
      "<p>Your intended use could be considered a significant change and qualify as a derivative work. In this case, you must contact the publisher directly and inquire, as HFA cannot advise if your change qualifies as a derivative work.</p><p>You can use the following databases to locate publisher contact information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a></p>"
  },
  {
    question: "How can I find the publisher information for a song?",
    answer:
      "<p>You can search for this information using our Public Search tool. To access this tool, please visit <a href='http://www.songfile.com/'>www.songfile.com</a> and click on the “Public Search” button at the top of the page.</p><p>After accepting the terms of the agreement, search for the song by title and writer. If you cannot locate the song, or if the publisher falls under \"copyright control\", we recommend searching the following databases for more information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a></p>"
  },
  {
    question:
      "Do I need a mechanical license if a song is in the public domain? How do I know if a song is in the public domain?",
    answer:
      "<p>Songs in the public domain do not require a mechanical license, however HFA cannot advise on public domain inquiries because our database cannot confirm such information. Your title could still require a mechanical license depending on the arrangement you plan to use. We apologize for the confusion and encourage you to research these titles in the following databases for further information:</p><p><a href='https://www.ascap.com/repertory'>https://www.ascap.com/repertory</a><br><a href='https://repertoire.bmi.com/StartPage.aspx'>https://repertoire.bmi.com/StartPage.aspx</a><br><a href='https://www.sesac.com/#/repertory/search'>https://www.sesac.com/#/repertory/search</a><br><a href='http://www.copyright.gov'>http://www.copyright.gov</a><br><a href='http://www.allmusic.com'>http://www.allmusic.com</a></p><p>a) If a song was copyrighted before the year 1923, it is in the public domain and a mechanical license is not required for its use. For further information, you may wish to contact the United States Copyright Office at (202) 707-6850 or <a href='https://www.copyright.gov/'>www.copyright.gov</a>. You may also wish to research your song using <a href='https://www.pdinfo.com/'>www.pdinfo.com</a>. Please note that neither of these websites is maintained by HFA, and we cannot be held responsible for their content.</p><p>b) If the song you wish to use is in the public domain, but you will be using a specific arrangement of that song that is still under copyright, you will need a mechanical license.</p>"
  },
  {
    question: "None of these questions are similar to mine.",
    answer:
      "<p><a href='https://www.harryfox.com/#/faq' target='_blank'>FAQs</a></p><p>If the FAQ page does not answer your question, please click the 'NEXT' button and describe your question in the box on the right.</p>"
  }
];

export default {
  questions
};
