import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VModal from "vue-js-modal";
import VueToastr from "vue-toastr";
import dotenv from "dotenv";

Vue.config.productionTip = false;

// - Vue Meta
import VueMeta from "vue-meta";
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.use(VModal, { dynamic: true, dialog: true, injectModalsContainer: true });
Vue.use(VueToastr, {
  defaultPosition: "toast-top-full-width"
});

import VueCookieBot from "@ambitiondev/vue-cookiebot-plugin";
Vue.use(VueCookieBot, {
  cookieBotID: "2953dd1c-5d30-4c5f-82f7-9cadd63e19c5",
  async: true
});

dotenv.config();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
