import articles from "../../api/articles";

const state = {
  articles: articles.articles
};

const getters = {
  articleById: state => id => {
    return state.articles.find(item => item.id == id);
  }
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
