<template>
  <img :src="url" :alt="name" />
</template>

<script>
export default {
  name: "HfaImage",
  props: ["name", "path"],
  computed: {
    url: function() {
      return require(`@/${this.path}/${this.name}.jpg`);
    }
  }
};
</script>

<style lang="css" scoped></style>
