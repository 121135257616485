<template>
  <div id="app">
    <router-view @hide-banner="hideBanner" />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: function() {
    return {
      titleTemplate: "The Harry Fox Agency%s"
    };
  },
  data: function() {
    return {
      showBanner: true
    };
  },
  methods: {
    toggleCovid: function() {
      this.showBanner = !this.showBanner;
      const routeData = this.$router.resolve({ name: "covid" });
      window.open(routeData.href, "_blank");
    },
    hideBanner: function() {
      this.showBanner = false;
    }
  },
  mounted: function() {
    this.$cookiebot.consentBanner();
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Abel|Antic+Slab|Fjalla+One");
// font-family: "Fjalla One", sans-serif;
// font-family: "Antic Slab", serif;
// font-family: "Abel", sans-serif;
body {
  font-size: 16px;
  letter-spacing: 0.00625em;
  overflow-x: hidden;
  margin: 8px 8px 0;

  @media (min-width: $desktop) {
    margin: 0 0 0 50px;
  }

  &.disable-scroll {
    overflow: hidden;
  }

  .toast-container {
    position: fixed;
    z-index: 999;
    top: 60px;
    left: 0;
    width: 100%;
    font-family: "Abel";
    font-weight: bold;
    font-size: 1.3em;

    > div {
      opacity: 1 !important;
    }
  }
}
.no-scroll {
  overflow: hidden;
}

// MODAL CLASSES
.v--modal-overlay .v--modal-box {
  overflow: visible !important;
  width: auto !important;
  /* top: initial !important; */
  left: initial !important;
  margin-bottom: 100px;
}

.v--modal-overlay .v--modal-background-click {
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-success,
.contact-form-modal {
  overflow: visible !important;
  width: auto !important;
  top: initial !important;
  left: initial !important;
}
.v--modal-box.v--modal.vue-dialog {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

#app {
  font-family: "Abel", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  .covid-banner {
    background-color: #000;
    padding: 10px 0;
    margin-left: -8px;
    margin-right: -8px;
    text-align: center;

    @media (min-width: $desktop) {
      margin-left: -50px;
      margin-right: 0;
    }

    a {
      font-size: 1.5em;
      font-weight: bold;
      color: #fff;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      @media (min-width: $desktop) {
        font-size: 2em;
      }
    }
  }
}
h1,
h2 {
  font-family: "Antic Slab", serif;
  font-weight: 400;
  color: #000;

  &.page-title {
    font-family: "Fjalla One", sans-serif;
    font-size: 1.75em;

    @media (min-width: $desktop) {
      font-size: 2em;
    }
  }
}
a {
  color: #000;
  text-decoration: none;
}
.mobile-only {
  display: none;

  @media (max-width: $mobile) {
    display: block;
  }
}
.desktop-only {
  display: none;

  @media (min-width: $desktop) {
    display: block;
  }
}
.jumbotron {
  h1 {
    font-family: "Fjalla One", sans-serif;
    color: #000;
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.2px;
  }
  p {
    line-height: 2.84;
    letter-spacing: 0.1px;
    font-size: 1em;
  }

  @media (min-width: $desktop) {
    h1 {
      font-size: 2em;
    }
    p {
      font-size: 1.1875em;
    }
  }
}
.button {
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  font-family: "Fjalla One", sans-serif;
  text-align: center;
  outline: none;

  &.default {
    border: 1px solid $yellow;
    padding: 0.6em 1.3em;
    color: $blue;
    background-color: #fff;

    &:hover {
      color: $yellow;
    }
  }
  &.primary {
    background-color: $yellow;
    color: #fff;
    padding: 0.6em 1.3em;

    &:hover {
      color: $blue !important;
    }
  }
  &.disabled {
    border: 1px solid #ccc;
    padding: 0.6em 1.3em;
    color: #999;
    background-color: #ccc;
    cursor: auto;
  }
  &.shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
}
.form-group {
  display: flex;
  flex-direction: column;

  @media (min-width: $desktop) {
    flex-direction: row;
    margin-top: 10px;
  }

  label {
    flex-shrink: 0;
    margin: 0 10px 0 0;
    display: block;
    text-align: left;

    @media (min-width: $desktop) {
      width: 200px;
      text-align: right;
    }
  }
  .input-field {
    input {
      border: 1px solid #999;
      border-radius: 3px;
    }
    select {
      width: 95%;
    }
    &.has-error * {
      border-color: red;
    }
  }
}
</style>
