<template>
  <div class="carousel">
    <div class="arrow right" @click="moveRight()">
      <img src="../assets/arrow.png" alt="arrow right" />
    </div>
    <div class="arrow left" @click="moveLeft()">
      <img src="../assets/arrow.png" alt="arrow left" />
    </div>
    <div class="dots">
      <div
        class="dot"
        v-for="(item, index) in items"
        :key="item.id"
        :class="{ current: isCurrent(index) }"
        @click="moveTo(index)"
      ></div>
    </div>
    <router-link
      :to="{ name: 'blog', params: { id: item.id } }"
      class="item"
      v-for="item in items"
      :key="item.id"
      :style="slideCarousel"
    >
      <div class="image">
        <HfaImage :name="item.id" path="assets/blogs" />
      </div>
      <div class="content">
        <div class="category">{{ item.category }}</div>
        <h3>{{ item.title }}</h3>
        <p>{{ item.subtitle }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import HfaImage from "@/components/HfaImage.vue";

export default {
  name: "Carousel",
  components: {
    HfaImage
  },
  props: ["items"],
  methods: {
    isCurrent: function(index) {
      return index == this.current;
    },
    moveRight: function() {
      clearInterval(this.loop);

      if (this.current < this.items.length - 1) {
        this.current++;
      } else {
        this.current = 0;
      }

      this.startLoop();
    },
    moveLeft: function() {
      clearInterval(this.loop);

      if (this.current == 0) {
        this.current = this.items.length - 1;
      } else {
        this.current--;
      }

      this.startLoop();
    },
    moveTo: function(index) {
      clearInterval(this.loop);

      this.current = index;

      this.startLoop();
    },
    startLoop: function() {
      this.loop = setInterval(() => {
        if (this.current < this.items.length - 1) {
          this.current++;
        } else {
          this.current = 0;
        }
      }, 5000);
    }
  },
  computed: {
    slideCarousel: function() {
      let percent, margin, aux;

      percent = this.current * 100;
      aux = window.innerWidth <= 1223 ? 8 : 70;
      margin = this.current * aux;

      return { transform: `translateX(calc(-${percent}% - ${margin}px))` };
    }
  },
  data: function() {
    return {
      current: 0,
      loop: null
    };
  },
  mounted: function() {
    this.startLoop();
  }
};
</script>

<style lang="scss">
.carousel {
  width: 100vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -8px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;

  @media (min-width: $desktop) {
    overflow: visible;
    margin-left: 0;
    margin-bottom: 100px;
    padding-bottom: 0;
    width: calc(100vw - 120px);
  }

  .arrow {
    display: none;

    @media (min-width: $desktop) {
      display: block;
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
      cursor: pointer;

      img {
        width: 150px;
      }
      &.left {
        left: 0;

        img {
          transform: rotate(90deg) translateY(55%);
        }
      }
      &.right {
        right: 0;

        img {
          transform: rotate(-90deg) translateY(55%);
        }
      }
    }
  }
  .dots {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    bottom: 0;

    .dot {
      margin: 0 20px;
      width: 15px;
      height: 15px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;

      &.current {
        background-color: #000;
      }
    }

    @media (min-width: $desktop) {
      bottom: -50px;
    }
  }

  .item {
    flex-shrink: 0;
    width: calc(100vw - 8px);
    margin: 0 4px;
    // margin-right: 70px;
    transition: transform 500ms ease-in-out;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    img {
      width: 100%;
      display: block;
    }
    .content {
      width: calc(100% - 2em);
      padding: 1em;
    }

    @media (min-width: $desktop) {
      width: calc(100vw - 120px);
      margin-right: 70px;
    }
  }
}
</style>
