<template>
  <div id="footer">
    <div class="background">
      <img src="../assets/wave-footer.png" alt="footer background" />
    </div>

    <div class="top">
      <div class="main-links">
        <router-link
          :to="{ name: link.to }"
          class="link"
          v-for="(link, index) in mainLinks"
          :key="index"
        >
          <span>{{ link.label }}</span>
          <img src="../assets/long-arrow.png" alt="long arrow icon" />
        </router-link>
      </div>
      <div class="contact">
        <img src="../assets/3d-cube.svg" alt="cubes in 3d" />
        <div class="link" @click="showContactForm"><span>Contact Us</span></div>
        <!-- <a class="newsletter">Sign up to receive updates</a> -->
      </div>
    </div>

    <div class="bottom">
      <div class="links">
        <div v-for="(link, index) in links" :key="index">
          <router-link v-if="link.to" :to="{ name: link.to }">{{
            link.label
          }}</router-link>
          <a v-if="link.click" @click="link.click">{{ link.label }}</a>
          <a v-if="link.href" :href="link.href" target="_blank">{{
            link.label
          }}</a>
        </div>
      </div>
      <div class="copyright">
        <span>&copy; 2019-2020 The Harry Fox Agency LLC</span>
        <div class="sesac-music-group">
          <a href="http://www.sesacmusicgroup.com/" target="_blank">
            <img
              src="@/assets/sesac-music-group-badge.png"
              alt="SESAC Music Group"
            />
          </a>
        </div>
      </div>
      <div class="follow">
        <div class="social-media">
          <div class="label">FOLLOW</div>
          <div class="link" v-for="(link, index) in socialLinks" :key="index">
            <a :href="link.href" target="_blank">
              <i :class="link.icon"></i>
            </a>
          </div>
        </div>
        <!-- <div class="newsletter"> -->
        <!--   <div class="label">NEWSLETTER</div> -->
        <!--   <a href="#">Sign Up to receive Updates</a> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import NewContact from "@/views/NewContact.vue";

export default {
  name: "Footer",
  data: function() {
    return {
      mainLinks: [
        {
          label: "RATE CHARTS",
          to: "rate-charts"
        },
        {
          label: "FAQs",
          to: "faq"
        },
        {
          label: "HEADLINES",
          to: "blog.index"
        }
      ],
      links: [
        { click: this.showContactForm, label: "Contact Us" },
        { to: "rate-charts", label: "Rate Charts" },
        { to: "faq", label: "FAQs" },
        { to: "hfa-affiliate", label: "Get Paid" },
        { to: "terms-of-use", label: "Terms of Use" },
        { to: "license-music", label: "License Music" },
        { to: "privacy-policy", label: "Privacy Policy - Your Privacy Rights" }
      ],
      socialLinks: [
        //{ icon: "fab fa-instagram", href: "" },
        {
          icon: "fab fa-linkedin-in",
          href: "https://www.linkedin.com/company/the-harry-fox-agency/"
        },
        {
          icon: "fab fa-facebook-f",
          href: "https://www.facebook.com/HarryFoxAgency/"
        },
        { icon: "fab fa-twitter", href: "https://twitter.com/harryfoxagency" }
      ]
    };
  },
  methods: {
    showContactForm: function() {
      this.$modal.show(
        NewContact,
        {},
        {
          classes: "v--modal new-contact-form-modal"
        },
        {
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose
        }
      );
    },
    beforeOpen: function() {
      document.body.classList.add("no-scroll");
    },
    beforeClose: function() {
      document.body.classList.remove("no-scroll");
    }
  }
};
</script>

<style lang="scss">
#footer {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding-right: 8px;

  .background {
    height: 80vh;
    background-color: #01012e;
    position: absolute;
    left: -8px;
    bottom: 0;
    width: 100vw;
    z-index: -10;

    img {
      width: 100%;
      transform: translateY(-32%);
      display: block;
    }
  }

  @media (min-width: $desktop) {
    margin-right: 0;
    margin-left: -60px;
    padding-left: 60px;

    .background {
      height: 600px;
      width: calc(100% + 60px);
      left: -60px;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (min-width: $desktop) {
      flex-wrap: nowrap;
    }

    .main-links {
      width: 100%;

      .link {
        display: block;
        color: #000;
        background-color: #fff;
        padding: 1.3em 2em;
        font-size: 1em;
        border-radius: 10px;
        box-shadow: 0 15px 60px -10px rgba(0, 0, 0, 0.3);
        position: relative;
        cursor: pointer;
        margin-bottom: 15px;

        span {
          font-weight: 900;
        }
        img {
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      @media (min-width: $desktop) {
        width: 30%;

        .link {
          font-size: 1.3em;
          padding: 1.3em 2em;
        }
      }
    }
    .contact {
      width: 100%;
      position: relative;

      img {
        display: none;
      }
      .link {
        cursor: pointer;
        background-color: $yellow;
        color: #fff;
        border-radius: 50%;
        width: 40vw;
        height: 40vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 75px auto 20px;

        span {
          font-size: 1.2em;
          font-weight: 400;
          font-family: "Fjalla One", sans-serif;
        }
      }
      .newsletter {
        display: block;
        text-align: center;
        color: #fff;
        text-decoration: underline;
        margin-bottom: 20px;
      }

      @media (min-width: $desktop) {
        width: 60%;

        img {
          display: block;
          position: absolute;
          top: 7.321vw;
          left: 20.132vw;
          width: 5.4178vw;
        }
        .link {
          position: absolute;
          top: -2.1962vw;
          left: 29.2826vw;
          width: 14.642vw;
          height: 14.642vw;

          span {
            font-size: 2em;
          }
        }
        .newsletter {
          display: none;
        }
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    .links {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0;

      > div {
        width: 33%;
        margin-bottom: 10px;

        a {
          cursor: pointer;
          color: #fff;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .copyright {
      padding-bottom: 10px;
      text-align: center;
      order: 99;
      border-top: 1px solid #fff;
      padding-top: 10px;
      width: 100%;

      span {
        color: #fff;
      }

      .sesac-music-group {
        a {
          display: block;
          margin-top: 20px;

          img {
            margin: 0 auto;
            width: 220px;
            display: block;
          }
        }
      }
    }
    .follow {
      width: 100%;
    }
    .social-media {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .label {
        display: none;
      }
      .link {
        font-size: 1.5em;
        margin-bottom: 10px;
        cursor: pointer;

        a {
          color: #fff;

          &:hover {
            color: $yellow;
          }
        }
      }
    }
    .newsletter {
      display: none;
    }

    @media (min-width: $desktop) {
      margin-top: 14.6413vw;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .links {
        width: 30%;
        margin: 0;
      }
      .copyright {
        align-self: flex-end;
        border: 0;
        width: auto;
        order: 0;
      }
      .follow {
        width: auto;
        color: #fff;
        display: flex;
        justify-content: flex-end;

        .label {
          width: 100%;
          margin-bottom: 10px;
        }

        .social-media {
          .label {
            display: block;
          }
          .link {
            width: 50%;
            font-size: 1.5em;
            margin-bottom: 10px;
            cursor: pointer;

            a {
              color: #fff;

              &:hover {
                color: $yellow;
              }
            }
          }
        }
        .newsletter {
          display: block;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
