<template>
  <div id="navbar">
    <router-link :to="{ name: 'home' }" class="logo" v-if="!noLogo">
      <img alt="HFA" src="../assets/logo-dark.png" />
    </router-link>

    <div v-if="!debug"></div>
    <div v-if="debug" class="dimensions">{{ width }} x {{ height }}</div>

    <div class="mobile-menu">
      <input type="checkbox" v-model="opened" />
      <span></span>
      <span></span>
      <span></span>

      <div class="menu">
        <ul>
          <li class="group">
            <span>services</span>
            <router-link :to="{ name: 'history' }">HISTORY</router-link>
            <router-link :to="{ name: 'exec-bios-index' }">TEAM</router-link>
            <router-link :to="{ name: 'blog.index' }">HEADLINES</router-link>
            <router-link :to="{ name: 'mlc' }">HFA & TheMLC</router-link>
            <router-link :to="{ name: 'faq' }">FAQs</router-link>
          </li>
          <li class="group">
            <span>About</span>
            <router-link :to="{ name: 'history' }">HISTORY</router-link>
            <router-link :to="{ name: 'exec-bios-index' }">TEAM</router-link>
            <router-link :to="{ name: 'blog.index' }">HEADLINES</router-link>
            <router-link :to="{ name: 'mlc' }">HFA & TheMLC</router-link>
            <router-link :to="{ name: 'faq' }">FAQs</router-link>
            <router-link :to="{ name: 'login' }">LOGIN</router-link>
            <router-link :to="{ name: 'sign-up' }">SIGN UP</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'hfa-affiliate' }">GET PAID</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'license-music' }"
              >LICENSE MUSIC</router-link
            >
          </li>
          <li class="login">
            <router-link :to="{ name: 'login' }" class="button primary"
              >LOGIN</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'sign-up' }">SIGN UP</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="links">
      <div class="dropdown">
        <div class="label">
          <router-link :to="{}">About</router-link>
          <div class="list">
            <router-link :to="{ name: 'history' }">HISTORY</router-link>
            <router-link :to="{ name: 'exec-bios-index' }">TEAM</router-link>
            <router-link :to="{ name: 'blog.index' }">HEADLINES</router-link>
            <router-link :to="{ name: 'mlc' }">HFA & TheMLC</router-link>
            <router-link :to="{ name: 'faq' }">FAQs</router-link>
            <router-link :to="{ name: 'login' }">LOGIN</router-link>
            <router-link :to="{ name: 'sign-up' }">SIGN UP</router-link>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'hfa-affiliate' }">GET PAID</router-link>
      <router-link :to="{ name: 'license-music' }">LICENSE MUSIC</router-link>
      <router-link :to="{ name: 'login' }" class="button primary"
        >LOGIN</router-link
      >
      <router-link :to="{ name: 'sign-up' }">SIGN UP</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  props: ["no-logo"],
  data: function() {
    return {
      opened: false
    };
  },
  computed: {
    debug: function() {
      return window.location.search !== "";
    },
    width: function() {
      return window.innerWidth;
    },
    height: function() {
      return window.innerHeight;
    }
  },
  watch: {
    opened: function(current) {
      if (current) {
        document.body.classList.add("disable-scroll");
      } else {
        document.body.classList.remove("disable-scroll");
      }
    }
  },
  beforeDestroy: function() {
    document.body.classList.remove("disable-scroll");
  }
};
</script>

<style lang="scss">
#navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Fjalla One", sans-serif;
  z-index: 2;
  position: relative;

  .dimensions {
    background-color: #fff;
  }

  @media (min-width: $desktop) {
    display: flex;
  }

  a {
    cursor: pointer;

    &:hover {
      color: $blue;
    }
  }

  .logo {
    img {
      height: 25px;
    }

    @media (min-width: $desktop) {
      margin: 20px 0 0 0;

      img {
        height: initial;
      }
    }
  }

  .mobile-menu {
    position: relative;

    input {
      opacity: 0;
      z-index: 3;
      cursor: pointer;
      position: absolute;
      margin: 0;
      width: 33px;
      height: 22px;
    }
    > span {
      display: block;
      width: 33px;
      height: 4px;
      margin-bottom: 5px;
      position: relative;
      background: #cdcdcd;
      border-radius: 3px;
      z-index: 2;
      transform-origin: 4px 0px;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

      &:first-child {
        transform-origin: 0% 0%;
      }
      &:nth-last-child(2) {
        transform-origin: 0% 100%;
      }
    }
    .menu {
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      z-index: 1;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-150%);
      transition: all 300ms ease-in-out;

      font-size: 0.85em;
      @media (min-height: 601px) {
        font-size: 1em;
      }
      @media (min-height: 800px) {
        font-size: 1.2em;
      }
      @media (min-height: 1025px) {
        font-size: 1.8em;
      }
      @media (min-height: 1200px) {
        font-size: 2em;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        width: 90%;

        li {
          text-align: center;

          a {
            margin: 0.6em 0;
            color: #000;
            display: block;
          }
          &.group {
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 2px;
            position: relative;
            padding: 10px;
            margin: 0 10vw;

            span {
              background-color: #fff;
              position: absolute;
              left: 50%;
              top: 0;
              padding: 0 5px;
              text-align: center;
              font-size: 0.7em;
              font-family: "Antic Slab", serif;
              text-transform: capitalize;
              transform: translate(-50%, -50%);
            }
          }
          &.login {
            border-top: 1px solid rgba(0, 0, 0, 0.3);
            margin-top: 35px;
            margin-bottom: 25px;
            padding-top: 25px;
          }
        }
      }
    }

    input:checked ~ .menu {
      transform: translateY(0);
    }
    input:checked ~ span {
      opacity: 1;
      transform: rotate(45deg) translate(1px, -5px);
      background-color: #000;

      &:nth-last-child(2) {
        transform: rotate(-45deg) translate(4px, 2px);
      }
      &:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
    }

    @media (min-width: $desktop) {
      display: none;
    }
  }
  &.dark-menu-icon .mobile-menu {
    > span {
      background-color: #000;
    }
  }
  &.light-menu-icon .mobile-menu {
    > span {
      background-color: #fff;
    }
  }

  .links {
    display: none;

    @media (min-width: $desktop) {
      width: 43%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 10px 0;

      > * {
        padding: 0.6em 1.3em;
        white-space: nowrap;
      }

      .dropdown {
        position: relative;

        .list {
          display: none;
          position: absolute;
          left: 10px;
          width: 100%;
          padding-top: 10px;
          text-align: left;
          font-size: 0.875em;

          background-color: #fffc;
          box-shadow: 0 0 15px 0 #0009;
          padding: 10px;

          a {
            padding: 5px 0;
          }
        }

        &:hover {
          .list {
            display: block;

            a {
              display: block;
            }
          }
        }
      }
    }
  }

  &.white-logo {
    .logo {
      img {
        filter: invert(100%);
      }
    }
  }
  &.white-text {
    a {
      color: #fff;

      &:hover {
        color: $yellow;
      }
    }
    .links {
      .dropdown {
        .list {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
