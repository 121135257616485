import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/covid-19-temporary-emergency-procedures",
      name: "covid",
      component: () => import("@/views/Covid.vue")
    },
    // {
    //   path: "/debug",
    //   name: "debug",
    //   component: () => import("@/views/Debug.vue")
    // },
    // {
    //   path: "/press",
    //   component: () => import("@/views/press/Press.vue"),
    //   children: [
    //     {
    //       path: "",
    //       name: "press.index",
    //       component: () => import("@/views/press/Index.vue")
    //     },
    //     {
    //       path: ":id",
    //       name: "press.view",
    //       component: () => import("@/views/press/View.vue")
    //     }
    //   ]
    // },
    {
      path: "/exec-bios",
      component: () => import("@/views/exec-bios/ExecBios.vue"),
      children: [
        {
          path: "",
          name: "exec-bios-index",
          component: () => import("@/views/exec-bios/Index.vue")
        },
        {
          path: ":id",
          name: "exec-bios-view",
          component: () => import("@/views/exec-bios/View.vue")
        }
      ]
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   component: () => import("@/views/About.vue")
    // },
    {
      path: "/blog-posts",
      name: "blog.index",
      component: () => import("@/views/BlogIndex.vue")
    },
    {
      path: "/blog/:id",
      name: "blog",
      component: () => import("@/views/BlogPost.vue")
    },
    {
      path: "/record-label",
      name: "record-label",
      component: () => import("@/views/RecordLabel.vue")
    },
    {
      path: "/history",
      name: "history",
      component: () => import("@/views/History.vue")
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue")
    },
    // {
    //   path: "/team",
    //   name: "team",
    //   component: () => import("@/views/Team.vue")
    // },
    {
      path: "/license-music",
      name: "license-music",
      component: () => import("@/views/LicenseMusic.vue")
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: () => import("@/views/SignUp.vue")
    },
    {
      path: "/get-paid",
      name: "get-paid",
      component: () => import("@/views/GetPaid.vue")
    },
    {
      path: "/rate-charts",
      name: "rate-charts",
      component: () => import("@/views/RateCharts.vue")
    },
    {
      path: "/hfa-and-the-mlc",
      name: "mlc",
      component: () => import("@/views/MLC.vue")
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/Faq.vue")
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("@/views/PrivacyPolicy.vue")
    },
    {
      path: "/terms-of-use",
      name: "terms-of-use",
      component: () => import("@/views/TermsOfUse.vue")
    },
    {
      path: "/hfa-affiliate",
      name: "hfa-affiliate",
      component: () => import("@/views/HfaAffiliate.vue")
    },
    {
      path: "/register-songs",
      name: "register-songs",
      component: () => import("@/views/RegisterSongs.vue")
    },
    {
      path: "/setup-hfa-portal",
      name: "setup-hfa-portal",
      component: () => import("@/views/SetupHfaPortal.vue")
    },
    {
      path: "/apply-today",
      name: "apply-today",
      component: () => import("@/views/HfaServices.vue")
    },
    {
      path: "/hfa-services",
      name: "hfa-services",
      component: () => import("@/views/HfaServices.vue")
    },
    {
      path: "/hfa-services/:status",
      name: "hfa-services-callback",
      component: () => import("@/views/HfaServicesCallback.vue")
    },
    {
      path: "/hfa-account/apply",
      name: "create-hfa-account",
      component: () => import("@/views/CreateHfaAccount.vue"),
      props: true
    },
    {
      path: "/hfa-account/direct/:code",
      name: "non-affiliate-link",
      component: () => import("@/views/CreateHfaAccount.vue"),
      props: true
      // redirect: { name: "register-hfa-account" }
    },
    {
      path: "/hfa-account/register",
      name: "register-hfa-account",
      component: () => import("@/views/CreateHfaAccount.vue"),
      props: true
    },
    {
      path: "/paypal-redirect",
      name: "paypal-redirect",
      component: () => import("@/views/PaypalRedirect.vue")
    },
    {
      path: "/pubonlineaccount",
      redirect: { name: "register-songs" }
    },
    {
      path: "/affiliation",
      redirect: { name: "hfa-affiliate" }
    },
    {
      path: "*",
      redirect: { name: "home" }
    },
    {
      path: "/:catchAll(.*)",
      redirect: { name: "home" }
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  } else {
    next();
  }
});

export default router;
