import executives from "../../api/executives";

const state = {
  bios: executives.bios
};

const getters = {
  bioById: state => id => {
    return state.bios.find(item => item.id == id);
  }
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
