import rate from "../../api/rate-charts";

const state = {
  charts: rate.charts
};

const getters = {
  chartById: state => id => {
    return state.charts.find(item => item.id == id);
  }
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
