import Vue from "vue";
import Vuex from "vuex";
import articles from "./modules/articles";
import executives from "./modules/executives";
import ratecharts from "./modules/ratecharts";
import blogPosts from "./modules/blog-posts";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    articles,
    executives,
    ratecharts,
    blogPosts
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {
    canonical: () => () => {
      return location.href;
    }
  }
});
