const charts = [
  {
    id: "physical",
    category: "Physical",
    name: "Compact Discs, LPs, cassettes and similar products:",
    short_description:
      "Recordings embodied in a Physical Medium. 37 CFR 385 Subpart B.",
    long_description: "37 CFR 385 Subpart B",
    pdf:
      "http://harryfox.com/content/hfa-rate-chart-mechanical-royalities-2024-CD-LP-Cassettes-Other-Recordings.pdf"
  },
  {
    id: "pdd",
    category: "Downloads",
    name: "PERMANENT DIGITAL DOWNLOADS",
    short_description:
      "A download that can be retained and played permanently, like songs downloaded to your PC or phone. 37 CFR 385 Subpart B.",
    long_description: "37 CFR 385 Subpart B",
    pdf: "http://harryfox.com/content/hfa-rate-chart-mechanical-royalities-2024-PDDs.pdf"
  },
  {
    id: "ringtone",
    category: "Downloads",
    name: "Ringtones",
    short_description:
      "Recordings downloaded to your phone that play when someone calls. 37 CFR 385 Subpart B.",
    long_description: "37 CFR 385 Subpart B",
    pdf: "http://harryfox.com/content/hfa-rate-chart-mechanical-royalities-2024-Ringtones.pdf"
  }
];

export default { charts };
