const bios = [
  {
    id: "michael-simon",
    name: "Michael Simon",
    position: "President & CEO",
    content:
      "<p>Michael S. Simon is the President of Rumblefish and President & CEO of HFA, premier music rights management organizations, which are subsidiaries of SESAC Holdings, Inc. Previously, Simon was HFA’s Senior Vice President of Business Affairs, General Counsel and Chief Strategic Officer.</p><p>Since joining HFA in 2001, Simon has directed and expanded the company’s traditional mechanical licensing business by creating its Rumblefish rights management service (formerly known as Slingshot) which provides customized, outsourced solutions for music distributors and rights holders. His team has developed groundbreaking licensing arrangements for online music and music video services, ringtones, lyrics, guitar tablature, background music, digital jukeboxes and other new media opportunities.</p><p>Before joining HFA, Simon was the Executive Vice President of Business Affairs and General Counsel of Razorfish, Inc. and President of Razorfish Studios. At Razorfish Studios, he was responsible for managing the creative development, business affairs and investor relations associated with the Studio’s recordings, motion picture productions, web sites, magazines, television programs, art & photography books and entertainment merchandise.</p><p>Prior to Razorfish, he founded Simon Ventures, a recording artist management and consulting company, whose roster included such major label acts as the Honeydogs and the Gigolo Aunts alongside several independent recording artists. Before that, Simon was Senior Director of Legal Affairs at PolyGram Records. He also served as an associate in the entertainment and intellectual property law practice of Levine Thall Plotkin & Menin and the corporate, litigation and entertainment practice of Rubin Baum Levin Constant & Friedman.</p><p>Simon received his Juris Doctor from the Columbia University School of Law and his Bachelor of the Arts, magna cum laude, from Amherst College.</p>"
  },
  {
    id: "stephen-block",
    name: "Stephen Block",
    position: "SVP of Business and Legal Affairs",
    content:
      "<p>St Stephen H. Block is the Senior Vice President of Business and Legal Affairs for HFA, Rumblefish, and Audiam. Previously Stephen was HFA’s Vice President of Business Affairs and Deputy General Counsel.</p><p>Since joining HFA in 2006, Stephen and his team have focused their efforts on developing and negotiating new media deals involving HFA and music publishers’ Internet businesses, including first-of-a-kind licensing and intellectual property administration arrangements for user-generated content, lyrics, tablature, background music, sheet music, synchronization and mechanical licensing. This work has led to the exponential growth of HFA’s rights administration services for publishers and digital music services providers alike. Stephen and his team also provide strategic advice and oversee HFA’s transactions and related matters.</p><p>Before joining HFA Stephen was the Executive Vice President of Business Affairs and General Counsel of several related companies, Digital Club Network, The Orchard and eMusic. Prior to that he was the Managing Partner of the intellectual property law firm Kahn & Block. Stephen started his career as a commercial litigation associate at Spector & Scher.</p><p>Stephen received his JD from the Cardozo School of Law where he was the Managing Editor of the Arts & Entertainment Law Journal and graduated magna cum laude from the Honors Program at Georgia State University.</p>"
  },
  {
    id: "ashley-morey",
    name: "Ashley Morey",
    position: "VP of Client Services",
    content:
      "<p>Ashley Morey is VP of Client Services at HFA. She earned her BBA in Music Business from Belmont University, during which time she interned in the Licensing and Membership departments at ASCAP Nashville. From there, she joined HFA as an Inquiry Agent in 2013 and worked her way up through the ranks, taking on the VP, Client Services role in November 2022. In this role, she oversees the Client Experience and Client Operations teams focused on supporting publisher and licensee processes.</p>"
  },
  {
    id: "cordella-brown",
    name: "Cordella Brown",
    position: "Senior Director, Distribution Operations",
    content:
      "<p>In her current role as Senior Director, Distribution Operation, Cordella Brown manages the daily operations of the Distributions Department. Along with her team, she ensures that mechanical royalties are accurately processed and distributed to publishers. With 35 years of experience at HFA, her areas of expertise include hiring and workforce supervision, staff development, customer service, process improvement, and more.</p>"
  },
  {
    id: "frank-neuschaefer",
    name: "Frank Neuschaefer",
    position: "Senior Director, Royalty Compliance",
    content:
      "<p>Frank Neuschaefer is HFA’s Senior Director Royalty Compliance, where he oversees the royalty examination process and partners with external examiners, while managing the daily operations of the Royalty Compliance Department through the supervision and development of its employees. Prior to joining the company in 1996, Neuschaefer served as a Senior Accountant at Sigmund Balaban & Co. He is a graduate of the University of Buffalo and Baruch College.</p>"
  },
  {
    id: "michael-smith",
    name: "Michael Smith",
    position: "VP, Legal & Business Affairs",
    content:
      "<p>As Vice President in the Business and Legal Affairs department of HFA, Rumblefish, and Audiam, Michael and his team advises and assists the other departments with any legal-related matters. As part of HFA’s legal team, Michael works with companies who want to engage HFA to provide it with data matching, licensing, and royalty distribution services. Since the passage of the Music Modernization Act, Michael has navigated the new mechanical licensing regime and its impact on licensees who want to license musical compositions.</p><p>Prior to Harry Fox, Michael was an associate in the litigation department of the international law firm Nixon Peabody. Before joining Nixon Peabody, Michael worked for the Honorable Richard J. Sullivan, formerly a District Court Judge in the Southern District of New York.</p><p>Michael received his JD from St. John’s University School of Law where he was an Articles Editor for the St. John’s Law Review. At St. John’s, Michael was a finalist for the Grammy Foundation’s Entertainment Law Initiative writing competition. His article argued for the existence of a New York state common-law performance right for pre-1972 sound recordings.</p><p>Prior to law school, Michael worked for independent music publishing companies as general manager. He handled all matters related to the companies, including copyright research and registration, song claiming, licensing, and co-publishing agreements.</p>"
  }
];
export default { bios };
