<template>
  <div id="home">
    <div class="background">
      <img src="@/assets/background-01.svg" alt="background image" />
    </div>

    <Navbar class="white-text light-menu-icon" />

    <section id="simplify-royalties">
      <div>
        <h1>
          Simplify and Streamline
          <br />
          Music Rights & Royalties
        </h1>
        <img src="../assets/laptop-over-charts.png" alt="Simplify" />
        <div class="actions">
          <router-link
            :to="{ name: 'license-music' }"
            class="button primary shadow"
            >LICENSE MUSIC</router-link
          >
          <router-link :to="{ name: 'hfa-affiliate' }" class="button default"
            >GET PAID</router-link
          >
        </div>
      </div>
      <div>
        <img src="../assets/laptop-over-charts.png" alt="Simplify" />
      </div>
    </section>

    <section class="music-users-publishers">
      <div id="music-users">
        <h2>Music Users</h2>

        <div class="text">
          <p>
            Whether you are a start-up, or an established digital music
            distributor, streaming app, or record label using music in new and
            innovative ways - we’ve got your back.
          </p>
          <p>
            HFA represents music publishers for physical and download mechanical
            licensing as well as licensing of other uses like lyrics and tabs.
            HFA simplifies & centralizes physical and download mechanical
            licensing for licensees.
          </p>
          <p>
            Power your service with Rumblefish, our signature suite of data,
            licensing and royalty administration services, which enables you to
            focus on building your business.
          </p>
          <p>
            We have the expertise, systems and team in place to manage all of
            your licensing needs, so you can stay focused on your business.
          </p>
        </div>

        <router-link
          :to="{ name: 'license-music' }"
          class="button primary shadow"
        >
          LICENSE MUSIC
        </router-link>

        <img src="../assets/3d-bars.svg" alt="Music Users" />
      </div>

      <div id="publishers">
        <h2>Publishers</h2>

        <div class="text">
          <p>
            Whether you are new to publishing or you’ve got decades of hits
            under your belt, HFA is here to help you with mechanical licensing
            and collecting the royalties you’re due.
          </p>
          <p>
            Powered by the largest database of music compositions matched to
            sound recordings, HFA connects music users and rightsholders to get
            royalties to music creators.
          </p>
          <p>
            Become an HFA affiliate and gain access to the most powerful payment
            engine in the business.
          </p>
        </div>

        <router-link
          :to="{ name: 'hfa-affiliate' }"
          class="button primary shadow"
        >
          GET PAID
        </router-link>

        <img src="../assets/screen-with-charts.svg" alt="Publishers" />
      </div>
    </section>

    <section id="company-highlights">
      <div class="background top">
        <img src="../assets/wave-footer.png" alt="background image" />
      </div>
      <div class="background">
        <img src="../assets/wave-footer.png" alt="background image" />
      </div>
      <h2>Company Highlights</h2>

      <div class="cards">
        <div class="card" v-for="(card, index) in cards" :key="card.id">
          <div class="image" :class="`img-${index}`">
            <img :src="card.img" :alt="card.title" />
          </div>
          <div class="content">
            <div class="text">
              <h3>{{ card.title }}</h3>
              <p v-html="card.text"></p>
            </div>
            <a v-if="card.to" :href="card.to" class="button default">{{
              card.button
            }}</a>
            <a
              v-if="card.link"
              :href="card.link"
              class="button default"
              target="_blank"
              >{{ card.button }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <section id="on-the-blog">
      <h2>On The Blog</h2>
      <Carousel :items="blogPosts" />
    </section>

    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import Carousel from "@/components/Carousel.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    Carousel,
    Footer
  },
  metaInfo: function() {
    return {
      meta: [
        {
          name: "description",
          content: "Simplify and Streamline Music Rights & Royalties"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    blogPosts: function() {
      return this.$store.state.blogPosts.posts.filter(
        i => i.content !== undefined
      );
    }
  },
  data: function() {
    return {
      cards: [
        {
          id: 1,
          img: require("../assets/company-highlights/company-highlight-01.jpg"),
          title: "RATES",
          text: "Dig into the facts behind mechanical licensing rates...",
          to: "#/press/classpass-live",
          button: "LEARN MORE"
        },
        {
          id: 2,
          img: require("../assets/company-highlights/company-highlight-02.jpg"),
          title: "IN THE NEWS",
          text:
            "Learn more about how HFA is supporting the MLC as a licensing and technology vendor",
          link:
            "https://www.forbes.com/sites/cheriehu/2018/08/02/fitness-marketing-revenue-music-tech",
          button: "READ MORE"
        },
        {
          id: 3,
          img: require("../assets/company-highlights/company-highlight-03.jpg"),
          title: "TEAM",
          text: "Learn more about our leadership team",
          to: "#/exec-bios",
          button: "READ MORE"
        },
        {
          id: 4,
          img: require("../assets/company-highlights/company-highlight-04.jpg"),
          title: "HISTORY",
          text: "Founded in 1927. A legacy of trust. A history of innovation.",
          to: "#/press/rumblefish-landr",
          button: "READ MORE"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#home {
  h1 {
    line-height: 1.44;
    margin-left: 20px;
    color: #fff;

    @media (min-width: $desktop) {
      color: $blue;
      font-size: 2.8125em;
      margin-left: 0;
      margin-bottom: 70px;
      text-shadow: 0.5px -0.5px #fff, 1px -1px #000;
    }
    @media (min-width: 1600px) {
      font-size: 4em;
    }
  }
  h2 {
    font-size: 1.75em;
    margin-top: 0;
  }
  p {
    font-size: 1em;
    line-height: 1.9;
    color: #000;

    @media (min-width: $desktop) {
      line-height: 2;
      margin: 10px 0;
    }
  }
  section {
    margin-bottom: 200px;

    .button {
      font-size: 1.375em;
      padding: 0.8em 0;
      width: 200px;
      display: block;
    }
  }

  > .background {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    overflow: hidden;

    img {
      width: 350vw;
      transform: translate(-30%, -35%) rotate(-100deg);
    }

    @media (min-width: $desktop) {
      img {
        transform: translate(10%, -37%) rotate(15deg);
        display: inline-block;
        width: 125vw;
      }
    }
  }

  #simplify-royalties {
    display: flex;
    justify-content: space-between;
    padding: 10.3vw 0 0 0;
    margin-bottom: 55vw;

    @media (min-width: 400px) {
      margin-bottom: 60vw;
    }
    @media (min-width: 430px) {
      margin-bottom: 65vw;
    }
    @media (min-width: 460px) {
      margin-bottom: 70vw;
    }
    @media (min-width: 490px) {
      margin-bottom: 75vw;
    }

    > div:first-child {
      img {
        width: 95%;
        display: block;
        margin: 0 auto 50px;
      }
    }
    > div:last-child {
      display: none;
    }

    .actions {
      a {
        display: block;
        width: 200px;
        margin: 0 auto 20px;
        font-size: 1.2em;
      }
    }

    @media (min-width: $desktop) {
      margin-bottom: 0;

      > div:first-child {
        img {
          display: none;
        }
        .actions {
          a {
            width: 250px;
            font-size: 1.375em;
            margin: 0 0 20px;
          }
        }
      }
      > div:last-child {
        display: block;

        img {
          display: block;
          margin: -40px 10px 0 0;
          width: 45vw;
        }
      }
    }
  }

  .music-users-publishers {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      width: calc(100% - 40px);
      padding: 0 20px;

      p {
        font-size: 1em;
        text-align: justify;
      }
      .button {
        margin: 20px auto 0;
      }
      img {
        display: block;
        width: 100%;
        margin: 40px auto 0;
      }
    }

    @media (min-width: $desktop) {
      justify-content: space-between;
      flex-direction: row;

      > div {
        width: calc(50% - 40px);
        flex-direction: column;

        .text {
          text-shadow: none;
          background-color: rgba(255, 255, 255, 0.75);
          border-radius: 5px;
          padding-right: 10px;
        }
        img {
          width: 50%;
        }
      }
    }
  }

  #company-highlights,
  #on-the-blog {
    h2 {
      font-size: 1.375em;
    }
  }
  #company-highlights {
    position: relative;

    h2 {
      color: #fff;
      margin-bottom: 1.5em;
    }

    .background {
      position: absolute;
      z-index: -1;
      width: 100vw;
      top: -50px;
      left: -8px;
      overflow: hidden;

      &.top {
        transform: scale(-1) translateY(100%);
      }

      img {
        width: 200vw;
        transform: scale(-1) translateX(25%);
        display: block;
      }

      @media (min-width: $desktop) {
        left: -60px;
        top: 0;
        overflow: visible;

        &.top {
          transform: scale(-1);
        }

        img {
          width: 100%;
          transform: scale(-1) translateY(-50%);
        }
      }
    }
    .cards {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: flex-start;

      .card {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        background-color: #fff;
        border-radius: 29px;
        margin-bottom: 25px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        width: 100%;

        .image {
          overflow: hidden;
          height: 100px;
          border-radius: 20px 20px 0 0;
          width: 100%;

          img {
            width: 100%;
          }

          &.img-0 img {
            transform: translateY(-25%);
          }
          &.img-1 img {
            transform: translateY(-5%);
          }
          &.img-2 img {
            transform: translateY(-15%);
          }
          &.img-3 img {
            transform: translateY(-25%);
          }
        }

        @media (min-width: $desktop) {
          margin-bottom: 50px;

          &:nth-child(1),
          &:nth-child(2) {
            width: 37.336vw;
          }
          &:nth-child(1),
          &:nth-child(3) {
            margin-right: 5.45%;
          }
          &:nth-child(2) {
            margin-top: -30px;
          }
          &:nth-child(3) {
            width: 29.283vw;
            margin-left: 8.5%;

            .content {
              .text {
                margin-left: 10px;
              }
              .button {
                margin-right: 10px;
              }
            }
          }
          &:nth-child(4) {
            width: 46.487vw;

            margin-top: -80px;
          }

          .image {
            &.img-0 {
              height: 190px;
              img {
                // transform: translateY(-25%);
              }
            }
            &.img-1 {
              height: 120px;
              img {
                transform: translateY(-10%);
              }
            }
            &.img-2 {
              height: 100px;
              img {
                transform: translateY(-25%);
              }
            }
            &.img-3 {
              height: 125px;
              img {
                transform: translateY(-35%);
              }
            }
          }
        }

        .content {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-end;

          .text {
            margin: 0 5px 0;
            width: 100%;

            h3 {
              font-family: "Fjalla One", sans-serif;
              font-weight: 400;
              text-transform: uppercase;
              margin-top: 5px;
              margin-bottom: 0;
              font-size: 1.188em;
            }
            p {
              font-size: 1em;
              line-height: 1.2em;
              padding-right: 10px;
              margin-top: 5px;

              > br {
                display: none;
              }
            }
          }
          .button {
            font-size: 1.188em;
            margin: 0 auto 1em;
          }

          @media (min-width: $desktop) {
            flex-wrap: nowrap;
            .text {
              margin: 0 0 0 30px;
              width: auto;

              h3 {
                margin-bottom: 0;
                margin-top: 20px;
              }
              p {
                margin-top: 5px;

                > br {
                  display: block;
                }
              }
            }
            .button {
              margin: 0 30px 1em 0;
              width: 150px;
            }
          }
        }
      }
    }
  }

  #on-the-blog {
    margin-left: -60px;
    padding-left: 60px;

    @media (min-width: $desktop) {
      overflow: hidden;
    }

    .carousel {
      .item {
        .category {
          text-transform: uppercase;
          color: $light-blue;
        }
        h3 {
          text-transform: uppercase;
          font-weight: 900;
          color: #000;
          font-size: 1.375em;
        }
        p {
          font-size: 1.188em;
          color: #000;
          margin-bottom: 10px;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    #navbar {
      .logo {
        img {
          filter: invert(100%);
        }
      }
    }
  }
}
</style>
